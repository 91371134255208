<template>
    <div class="home">
        <div ref="file"></div>
    </div>
</template>

<script>
  import { renderAsync } from 'docx-preview'
  import axios from 'axios'
  window.JSZip = require('jszip')
  export default {
    created() {
      this.previewWord()
    },
    data(){
      return {
        accessToken: localStorage.getItem('accessToken'),
        baseUrl: window.g.baseUrl,
        id: this.$route.query.id
      }
    },
    methods:{
// 渲染 word 文件内容
      previewWord() {
        axios({
          method: 'get',
          responseType: 'blob', // 设置响应文件格式
          // url: 'https://services.pisecuriti.com/ent/files/template/企业隐私管理政策模版V1.0.docx',
          url: this.baseUrl+'/enterprise/privacyPolicy/GetPrivacyPolicyDisplayFile?id='+this.id+'&x-token='+this.accessToken,
        }).then(({data}) => {
          renderAsync(data,this.$refs.file) // 渲染到页面预览
        })

      },
    },


  }
</script>

<style scoped>
    html,body{
        margin: 0;
        padding: 0;
    }
    ul,li{
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .mt10{margin-top: 10px}
    .mb30{margin-bottom: 30px}
    .flex-start{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .flex-between{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .triangle{
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 16px 0 14px 20px;
        border-color: transparent transparent transparent #0d40b2;
        position: absolute;
        top: 0;
        right: -20px;

    }
    .wrap{
        /*width: 954px;*/
        width: 1200px;
        margin: 0 auto;
        padding: 70px 0;
        text-align: center;
    }
    .report-info{
        color: #0d40b2;
    }
    .report-title{
        text-align: center;
        margin-bottom: 150px;
        letter-spacing: 4px;
    }
    .report-name{
        font-size: 36px;
        font-weight: 500;
    }
    .report-sub-name{
        font-size: 32px;
    }
    .access-info{
        margin:70px auto 0 auto;
        color: #0d40b2;
        width: 680px;
    }
    .access-info  li{
        margin-bottom: 50px;
        font-size: 18px;
    }
    .access-info ul  span{
        letter-spacing: 2px;
    }
    .access-info-item span:first-child{
        font-weight: 500;
        display: inline-block;
        width: 120px;
    }
    .access-info-item span:last-child{
        border-bottom: 1px solid;
        display: inline-block;
        width: 340px;
        text-align: left;
        position: relative;
        top: -4px;
        /*padding-bottom: 6px;*/
        /*box-sizing: border-box;*/
    }
    .report-content{
        padding: 0 150px;
    }
    .report-item{
        margin: 120px 0 30px 0;
    }
    .report-item-title{
        color: #270000;
        text-align: left;
        font-size: 20px;
        border-bottom: 2px solid #3333;
        letter-spacing: 2px;
        padding-bottom: 10px;
        /*margin-bottom: 30px;*/
    }
    table{
        width: 100%;
        /*border: 1px solid #0d40b2;*/
        border-collapse:separate;
        box-sizing: border-box;
        border-left: 1px solid #0d40b2;
        border-right: 1px solid #0d40b2;
        border-spacing: 0;
    }
    thead{
        height: 46px;
        color: #ffffff;
        background: #0d40b2;
        box-sizing: border-box;
    }
    td,th{
        box-sizing: border-box;
        border: 1px solid #0d40b2;
    }
    tr{
        /*border-left: 2px solid #0d40b2;*/
        /*border-right: 2px solid #0d40b2;*/
        /*border-bottom: 2px solid #0d40b2;*/
        box-sizing: border-box;
    }
    .td-label{
        color: #270000;
    }
    .td-text{
        color: #666666;
        text-align: left;
        padding-left: 20px;
        font-size: 13px;
    }
    .report-base-info tr td:first-child{
        border-right: 1px solid #0d40b2;
        width: 180px;
    }
    .table-title{
        height: 46px;
        color: #ffffff;
        background: #0d40b2;
    }
    .report-base-info td{
        height: 60px;
    }
    .scene-item{
        display: flex;
        align-items: center;
        margin-bottom: 5px;
    }
    .check-bg{
        width: 16px;
        height: 16px;
        color: #ffffff;
        font-size: 12px;
        text-align: center;
        margin-right: 10px;
        box-sizing: border-box;
        border-radius: 2px;
    }
    .check{
        background: #0d40b2;
    }
    .uncheck{
        border: 1px solid #3333;
    }

    .statement-item{
        display: flex;
    }
    .statement-cricle{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #0d40b2;
        flex: 0 0 auto;
    }
    .statement-content{
        letter-spacing: 2px;
        text-align: left;
        color: #666666;
        line-height: 1.5;
        padding: 0 0 20px 20px;
        position: relative;
    }
    .cricle{
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #0d40b2;
        display: inline-block;
    }
    .statement-content:before{
        content: '';
        width: 2px;
        height: 100%;
        background: #0d40b2;
        position: absolute;
        top: 0;
        left: -10px;
        z-index: -1;
    }
    #suggest-wrap .statement-content:before{
        display: none;
    }
    #suggest-wrap .statement-cricle{
        width: 10px;
        height: 10px;
        position: relative;
        top: 6px;
    }
    #suggest-wrap .statement-content{
        font-size: 13px;
        padding: 0 0 20px 10px;
    }
    .statement-unit{
        text-align: right;
        color: #666666;
    }
    .report-sub-title{
        height: 30px;
        text-align: left;
        min-width: 160px;
        width: fit-content;
        color: #ffffff;
        line-height: 30px;
        padding:0 10px;
        margin:30px 0 20px 0;
        background: #0d40b2;
        position: relative;
    }
    .report-sub-title:before{
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 16px 0 14px 20px;
        border-color: transparent transparent transparent #0d40b2;
        position: absolute;
        top: 0;
        right: -20px;
    }
    .color-1{color: #F86064 !important;}
    .color-2{color: #FF9900 !important;}
    .color-3{color: #A4BA4C !important;}
    .color-4{color: #19BE6B !important;}
    .color-5{color: #AABE63 !important;}
    .color-6{color: #19BE6B !important;}
    .color-7{color: #F86064 !important;}
    .bg-1{background:rgb(255, 153, 0);color: #ffffff!important;}
    .bg-2{background:rgb(248, 96, 100);color: #ffffff !important;}
    .desc-text{
        text-align: left;
        color: #666666;
        padding-left: 40px;
        letter-spacing: 2px;
        line-height: 1.5;
        font-size: 13px;
        /*margin-bottom: 30px;*/
    }
    .level-table td{
        color: #666666;
        padding: 20px;
        letter-spacing: 2px;
        font-size: 12px;
        height: 30px;
    }
    .table-6 tr td:last-child{
        font-size: 14px;
    }
    .table-5 tr td:first-child{
        width: 500px;

    }
    .table-6 tr td:first-child,
    .table-6 tr td:last-child{
        width: 120px;
    }
    .table-6 tr td:nth-child(2){
        width: 200px;
    }
    .chart-wrap{
        width: 100%;
        border: 1px solid #eeeeee;
        border-radius: 10px;
    }
    .chart-title-wrap{
        height: 40px;
        background: rgb(242,242,242);
        padding: 0 20px;
    }
    .chart-title{
        font-size: 16px;
        color: #666666;
    }
    .level-rect{
        width: 20px;
        height: 10px;
        display: inline-block;
    }
    .level-rect-1{background: rgb(102,102,102)}
    .level-rect-2{background: rgb(44, 186, 156)}
    .level-rect-3{background: rgb(63, 152, 212)}
    .level-rect-4{background: rgb(255,153,0)}
    .level-rect-5{background: rgb(248, 96, 100)}
    .chart-level{margin-right: 10px;color: #666666;font-size: 13px}
    .chart-box{
        width: 100%;
        height: 300px;
    }
    .chart-process-wrap{
        padding-top: 30px;
    }
    .chart-process-item{
        padding: 5px 50px;
    }
    .chart-process-item span{
        font-size: 13px;
        color: #666666;
    }
    .chart-process-out{
        margin: 0 10px;
        width: 450px;
        height: 10px;
        border-radius: 20px;
        background: rgb(242,242,242);
    }
    .chart-process-inner{
        /*width: 80%;*/
        height: 100%;
        background: #e34b43;
        border-radius: 20px;
    }
    .chart-legend{
        padding: 30px 50px;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    .chart-lengend-item{
        width: 50%;
        text-align: left;
    }
    .chart-lengend-item span{
        color: #666666;
        font-size: 13px;
        margin-left: 10px;
        /*display: flex;*/
        /*justify-content: space-around;*/
        /*align-items: center;*/
    }
    .chart-lengend-item span:first-child{
        display: inline-block;
        width: 10px;
        height: 10px;
        background: #3333;
    }
    .chart-pie2{
        width: 48%;
    }
    .chart-bar{
        width: 52%;
    }
    .chart-pie2,
    .chart-bar{
        width: 50%;
        height: 300px;
    }
    .appendix-wrap{
        color: #666666;
    }
    .appendix-title{
        text-align: left;
        margin-bottom: 10px;
    }
    .appendix-wrap .scene-text{
        font-size: 13px;
    }
    .appendix-item{
        border: 1px solid #3333;
        padding: 10px;
        margin-bottom: 10px;
    }
    .reportUnit-text{
        color: #0d40b2;
        font-size: 16px
    }
</style>
